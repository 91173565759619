@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css');

*{
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  width: .4rem;
  height: .4rem;
  background-color: transparent;
  border-radius: .5rem;
}
::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: rgba(14, 0, 23, 0.4);
}
::selection {
  background: #7c3aed
;
  color: white;
}
/* body{
  background-size: cover;
  background-repeat: no-repeat;
} */

#MsgContainer{
  animation: slide-up 0.4s ease;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .InputContainer{
  animation: slide-up 0.4s ease;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

.formContainer{
  height: 100vh;
  width: 100vw;
  display:flex;
  justify-content: center;
  align-items: center;
}
.RegisterForm{
  background: url("/src/Images/Register_Bg.jpg");
  background-size: cover;
}
.LoginForm{
  background: url("/src/Images/Login_Bg.jpg");
  background-size: cover;
}
.formWrapper {
  background:rgba(255,255,255,0.2);
  backdrop-filter: blur(10px);
  padding: 20px 60px;
  border-radius:26px;
  flex-direction: column;
  display: flex;
  gap:10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* width: 80vw; */
}
.formWrapper form {
  display: flex;
  flex-direction: column;
  gap: 17px;
  /* justify-content: center; */
  /* align-items: center; */
}
.formWrapper .logo {
  color:#ff0058;
  font-weight: 900;
  font-size:2rem;
  text-transform: uppercase;
}
.formWrapper .title {
  color:#4dff03;
  font-weight: 800;
  font-size:1.4rem;
  text-transform: uppercase;
}
.formWrapper form input {
  padding-left: 26px;
  padding-right: 26px;
  padding-top:17px;
  padding-bottom:17px;
  outline:none;
  border: 1px solid #a7bcff;
  color:#ff0058;
  border-radius:80px;
  font-weight: 800;
  min-width:max-content;
  background-color: rgba(255,255,255,0.4);
  backdrop-filter: blur(10px);
  /* width: 60vw; */
  text-align: center;
}
.formWrapper form input::placeholder {
  color:#ffbc00;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}
.formWrapper button {
  background-color: #a7bcff;
  color:white;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border:none;
  border-radius:10px;
  font-size: 1rem;
  /* width: 40vw; */
  transition:0.6s;
}
/* .formWrapper button:hover {
  width: 60vw;
} */
.formWrapper p {
  color:#08ff98     ;
  font-size: 1.6rem;
  margin-top: 10px;
}
.formWrapper label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.formWrapper span {
  margin-left: 8px;
  color: #f58;
  font-weight: 800;
  text-transform: uppercase;
}
.errorpage {
  background: url("https://images.wallpapersden.com/image/download/purple-sunrise-4k-vaporwave_bGplZmiUmZqaraWkpJRmbmdlrWZlbWU.jpg")
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  scroll-behavior: smooth;
  }
  .error-container {
  text-align: center;
  font-size: 106px;
  font-family: "Catamaran", sans-serif;
  font-weight: 800;
  margin: 70px 15px;
  }
  .error-container > span {
  display: inline-block;
  position: relative;
  }
  .error-container > span.four {
  width: 136px;
  height: 43px;
  border-radius: 999px;
  background: linear-gradient(
      140deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.07) 43%,
      transparent 44%,
      transparent 100%
    ),
    linear-gradient(
      105deg,
      transparent 0%,
      transparent 40%,
      rgba(0, 0, 0, 0.06) 41%,
      rgba(0, 0, 0, 0.07) 76%,
      transparent 77%,
      transparent 100%
    ),
    linear-gradient(to right, #d89ca4, #e27b7e);
  }
  .error-container > span.four:before,
  .error-container > span.four:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 999px;
  }
  .error-container > span.four:before {
  width: 43px;
  height: 156px;
  left: 60px;
  bottom: -43px;
  background: linear-gradient(
      128deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.07) 40%,
      transparent 41%,
      transparent 100%
    ),
    linear-gradient(
      116deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.07) 50%,
      transparent 51%,
      transparent 100%
    ),
    linear-gradient(to top, #99749d, #b895ab, #cc9aa6, #d7969e, #e0787f);
  }
  .error-container > span.four:after {
  width: 137px;
  height: 43px;
  transform: rotate(-49.5deg);
  left: -18px;
  bottom: 36px;
  background: linear-gradient(
    to right,
    #99749d,
    #b895ab,
    #cc9aa6,
    #d7969e,
    #e0787f
  );
  }
  .error-container > span.zero {
  vertical-align: text-top;
  width: 156px;
  height: 156px;
  border-radius: 999px;
  background: linear-gradient(
      -45deg,
      transparent 0%,
      rgba(0, 0, 0, 0.06) 50%,
      transparent 51%,
      transparent 100%
    ),
    linear-gradient(
      to top right,
      #99749d,
      #99749d,
      #b895ab,
      #cc9aa6,
      #d7969e,
      #ed8687,
      #ed8687
    );
  overflow: hidden;
  animation: bgshadow 5s infinite;
  }
  .error-container > span.zero:before {
  content: "";
  display: block;
  position: absolute;
  transform: rotate(45deg);
  width: 90px;
  height: 90px;
  background-color: transparent;
  left: 0px;
  bottom: 0px;
  background: linear-gradient(
      95deg,
      transparent 0%,
      transparent 8%,
      rgba(0, 0, 0, 0.07) 9%,
      transparent 50%,
      transparent 100%
    ),
    linear-gradient(
      85deg,
      transparent 0%,
      transparent 19%,
      rgba(0, 0, 0, 0.05) 20%,
      rgba(0, 0, 0, 0.07) 91%,
      transparent 92%,
      transparent 100%
    );
  }
  .error-container > span.zero:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  left: 43px;
  bottom: 43px;
  background: #fdfaf5;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
  }
  
  .screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
  }
  
  @keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
  }
  }
  .errorpage {
  font-family: "Montserrat", Helvetica, sans-serif;
  color: #bbb;
  }
  .errorpage h1 {
  text-align: center;
  margin: 30px 15px;
  }
  .zoom-area {
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
  }
  .link-container {
  text-align: center;
  }
  a.more-link {
  text-transform: uppercase;
  font-size: 17px;
  background-color: #de7e85;
  padding: 17px 17px;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  line-height: 1.5;
  text-decoration: none;
  margin-top: 44px;
  letter-spacing: 1px;
  border-radius: 44px;
  }
  .more-link-button {
  text-transform: uppercase;
  font-size: 17px;
  background-color: #de7e85;
  padding: 17px 17px;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  line-height: 1.5;
  text-decoration: none;
  margin-top: 44px;
  letter-spacing: 1px;
  border-radius: 44px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  }
  
  .loader-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #242f3f;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 10000000000000000000000000000000000000000000000000000000;
  }
  
  .loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader div:nth-child(1) {
    background: rgb(26, 255, 0);
  }
  
  .loader div:nth-child(2) {
    background: rgb(255, 251, 0);
  }
  
  .loader div:nth-child(3) {
    background: rgb(255, 123, 0);
  }
  
  .loader div:nth-child(4) {
    background: rgb(255, 0, 0);
  }
  
  .loader::before {
    content: "LOADING";
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(8px);
    position: absolute;
    width: 350px;
    height: 170px;
    z-index: 20;
    border-radius: 0 0 10px 10px;
    border: 1px solid rgba(255, 255, 255, 0.274);
    border-top: none;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.082);
    animation: anim2 2s infinite;
    font-size: 4rem;
    color: #ff0058;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 170px;
    padding-top: 44px;
  }
  
  .loader div {
    background: rgb(228, 228, 228);
    border-radius: 50%;
    width: 53px;
    height: 53px;
    z-index: -1;
    animation: anim 2s infinite linear;
    animation-delay: calc(-0.3s * var(--i));
    transform: translateY(-110px);
    margin: 0.2em;
  }
  
  @keyframes anim {
    0%,
    100% {
        transform: translateY(-100px);
        filter: hue-rotate(0deg);
    }
    50% {
        transform: translateY(26px);
        filter: hue-rotate(360deg);
    }
  }
  
  @keyframes anim2 {
    0%,
    100% {
        transform: rotate(-10deg);
        filter: hue-rotate(0deg);
    }
    50% {
        transform: rotate(10deg);
        filter: hue-rotate(360deg);
    }
  }
.ChangePassword{
  background:url("/src/Images/ChangePassword_Bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
.ChangePassword .ChangePasswordContainer{
  background-color:rgb(255,255,255,0.4);
  backdrop-filter: blur(10px);
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  border-radius:44px;
  flex-direction: column;
}
.ChangePassword .ChangePasswordContainer h1{
  color: #ffbc00;
  font-weight: 900;
  text-transform: uppercase;
  font-size:2rem;
}
.ChangePassword .ChangePasswordContainer form{
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.ChangePassword .ChangePasswordContainer form input {
  padding-left: 26px;
  padding-right: 26px;
  padding-top:17px;
  padding-bottom:17px;
  outline:none;
  border: 1px solid #a7bcff;
  color:#ff0058;
  border-radius:80px;
  font-weight: 800;
  background-color: rgba(255,255,255,0.4);
  backdrop-filter: blur(10px);
  text-align: center;
  display: inline;
  width: 100%;

}
.ChangePassword .ChangePasswordContainer form input::placeholder {
  color:#ff0058;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}
.ChangePassword .ChangePasswordContainer button {
  background-color: #a7bcff;
  color:white;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border:none;
  border-radius:10px;
  font-size: 1rem;
  transition:0.6s;
  margin: 26px;
}
#BackButton{
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: rgba(0,0,0,.6);
  border: none;
  border-radius: 26px;
  color: cyan;
  cursor: pointer;
  font-weight: 900;
  outline: none;
  padding: 17px;
  text-transform: uppercase;
}
#MoreOptions {
  visibility: hidden;
}
/* .Message:hover #MoreOptions {
  visibility: visible;
} */
@media screen and (min-width:768px) {
  .Message:hover #MoreOptions {
      visibility: visible;
    }
}
#ToolboxContainer.ToolBoxIsActive {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur("10px");
  border-radius: 26px
}
#Message .MoreTools {
  color: #fff;
  font-weight: 900;
}

.MoreTools {
  color: #fff;
  font-weight: 900;
}
/* #MoreOptions {
  font-size: 1.7rem;
  padding-right: 17px;
  cursor: pointer;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  font-weight: 900;
  color: #ff0058;
} */
#MoreOptions .MoreTools {
  font-size: 1.4rem;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  margin-right: 8px;
  /* background-color:#2f2d52; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
}
#Toolbox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 26px;
  cursor: pointer;
  gap: 26px;
}
/* #Input:active{
  border: none;
} */
/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

/*===== VARIABLES CSS =====*/
:root {
  /*===== Colores =====*/
  --first-color: #9D4BFF;
  --first-color-dark: #23004D;
  --first-color-light: #A49EAC;
  --first-color-lighten: #F2F2F2;

  /*===== Font and typography =====*/
  --body-font: 'Open Sans', sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
}

@media screen and (min-width: 768px) {
  :root {
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
  }
}

/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}

#FormBody {
  /* background: url("/src/Images/Login_Bg.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--first-color-dark);
}

h1 {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/*===== LOGIN =====*/
.login {
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.login__content {
  display: grid;
}

.login__img {
  justify-self: center;
}

.login__img img {
  width: 310px;
  margin-top: 1.5rem;
}

.login__forms {
  position: relative;
  height: 368px;
}

.loginform_reg {
  position: relative;
  height: 520px;
}

.login__registre,
.login__create {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  background-color: var(--first-color-lighten);
  padding: 2rem 1rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 8px 20px rgba(35, 0, 77, .2);
  animation-duration: .4s;
  animation-name: animate-login;
}

@keyframes animate-login {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.login__title {
  font-size: var(--h1-font-size);
  margin-bottom: 2rem;
}

.login__box {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: .5rem;
  padding: 1.125rem 1rem;
  background-color: #FFF;
  margin-top: 1rem;
  border-radius: .5rem;
}
.login__box_pfp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* grid-template-columns: max-content 1fr;
  column-gap: .5rem; */
  padding: 1.125rem 1rem;
  background-color: #FFF;
  margin-top: 1rem;
  border-radius: .5rem;
}

.login__icon {
  font-size: 1.5rem;
  color: var(--first-color);
}

.login__input {
  border: none;
  outline: none;
  font-size: var(--normal-font-size);
  font-weight: 700;
  color: var(--first-color-dark);
}

.login__input::placeholder {
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
  color: var(--first-color-light);
}

.login__forgot {
  display: block;
  width: max-content;
  margin-left: auto;
  margin-top: .5rem;
  font-size: var(--small-font-size);
  font-weight: 600;
  color: var(--first-color-light);
}

.login__button {
  display: block;
  padding: 1rem;
  margin: 2rem 0;
  background-color: var(--first-color);
  color: #FFF;
  font-weight: 600;
  text-align: center;
  border-radius: .5rem;
  transition: .3s;
}

.login__button:hover {
  background-color: var(--first-color-dark);
}

.login__account,
.login__signin,
.login__signup {
  font-weight: 600;
  font-size: var(--small-font-size);
}

.login__account {
  color: var(--first-color-dark);
}

.login__signin,
.login__signup {
  color: var(--first-color);
  cursor: pointer;
}

.login__social {
  margin-top: 2rem;
}

.login__social-icon {
  font-size: 1.5rem;
  color: var(--first-color-dark);
  margin: 0 1.5rem;
}

/*Show login*/
.block {
  display: block;
}

/*Hidden login*/
.none {
  display: none;
}

/* ===== MEDIA QUERIES =====*/
@media screen and (min-width: 576px) {
  .login__forms {
    width: 348px;
    justify-self: center;
  }
}

@media screen and (min-width: 1024px) {
  .login {
    height: 100vh;
    overflow: hidden;
  }

  .login__content {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    align-items: center;
    margin-left: 10rem;
  }

  .login__img {
    display: flex;
    width: 600px;
    height: 588px;
    background-color: var(--first-color-lighten);
    border-radius: 1rem;
    padding-left: 1rem;
  }

  .login__img img {
    width: 390px;
    margin-top: 0;
  }

  .login__registre,
  .login__create {
    left: -11rem;
  }

  .login__registre {
    bottom: -2rem;
  }

  .login__create {
    bottom: -5.5rem;
  }
}

.top-to-btm {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateY(-60px);
}

.icon-position {
  /* position: fixed;
  right: 30vw; */
  bottom: 150px;
  z-index: 40;
}

.icon-style {
  background-color: #551b54;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 4s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #551b54;
  border: 2px solid #551b54;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@import url("https://fonts.googleapis.com/css?family=Passion+One");

/* * {
  box-sizing: border-box;
} */

#NoInternet {
  --unit: 5;
  align-items: center;
  /* background-color: #111; */
  display: flex;
  font-family: 'Passion One', cursive;
  justify-content: center;
  min-height: 100vh;
}

@media (min-width: 768px) {
  #NoInternet {
    --unit: 10;
  }
}

.badge {
  --size: 150;
  height: calc(var(--size) * 1px);
  position: relative;
  width: calc(var(--size) * 1px);
}

@media (min-width: 768px) {
  .badge {
    --size: 300;
  }
}

.badge__circle {
  background: #f00;
  border: 10px solid #000;
  border-radius: 100%;
  box-shadow: 0 0 0 10px #ff0;
  height: 100%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
}

.badge__text {
  --font-size: calc(var(--size) * 0.25px);
  background: #000;
  color: #fff;
  font-size: var(--font-size);
  left: 50%;
  letter-spacing: calc(var(--font-size) * 0.25px);
  line-height: var(--font-size);
  padding: 0 0.5rem;
  position: absolute;
  text-transform: uppercase;
  top: 55%;
  transform: translate(-50%, 0);
  white-space: nowrap;
  z-index: 3;
}

.badge__text--empty {
  box-shadow: 0 0 0 10px #ff0;
  color: transparent;
  z-index: 1;
}

.dino {
  height: 75%;
  position: relative;
  width: 75%;
  z-index: 2;
}

.dino__head {
  -webkit-clip-path: polygon(0 calc(var(--unit) * 1px), calc(var(--unit) * 1px) calc(var(--unit) * 1px), calc(var(--unit) * 1px) 0, calc(var(--unit) * 9px) 0, calc(var(--unit) * 9px) calc(var(--unit) * 1px), calc(var(--unit) * 10px) calc(var(--unit) * 1px), calc(var(--unit) * 10px) calc(var(--unit) * 6px), calc(var(--unit) * 5px) calc(var(--unit) * 6px), calc(var(--unit) * 5px) calc(var(--unit) * 7px), calc(var(--unit) * 8px) calc(var(--unit) * 7px), calc(var(--unit) * 8px) calc(var(--unit) * 8px), 0 calc(var(--unit) * 8px));
  -webkit-animation: roar 4s infinite linear;
  animation: roar 4s infinite linear;
  background: #000;
  clip-path: polygon(0 calc(var(--unit) * 1px), calc(var(--unit) * 1px) calc(var(--unit) * 1px), calc(var(--unit) * 1px) 0, calc(var(--unit) * 9px) 0, calc(var(--unit) * 9px) calc(var(--unit) * 1px), calc(var(--unit) * 10px) calc(var(--unit) * 1px), calc(var(--unit) * 10px) calc(var(--unit) * 6px), calc(var(--unit) * 5px) calc(var(--unit) * 6px), calc(var(--unit) * 5px) calc(var(--unit) * 7px), calc(var(--unit) * 8px) calc(var(--unit) * 7px), calc(var(--unit) * 8px) calc(var(--unit) * 8px), 0 calc(var(--unit) * 8px));
  height: calc(var(--unit) * 8px);
  position: absolute;
  right: 0;
  top: calc(var(--unit) * 2px);
  transform-origin: 30% 100%;
  width: calc(var(--unit) * 10px);
}

.dino__head:after {
  background: #f00;
  content: '';
  height: calc(var(--unit) * 1px);
  left: 20%;
  position: absolute;
  top: 20%;
  width: calc(var(--unit) * 1px);
}

.dino__body {
  -webkit-clip-path: polygon(0 0, calc(var(--unit) * 1px) 0, calc(var(--unit) * 1px) calc(var(--unit) * 2px), calc(var(--unit) * 2px) calc(var(--unit) * 2px), calc(var(--unit) * 2px) calc(var(--unit) * 3px), calc(var(--unit) * 3px) calc(var(--unit) * 3px), calc(var(--unit) * 3px) calc(var(--unit) * 4px), calc(var(--unit) * 4px) calc(var(--unit) * 4px), calc(var(--unit) * 5px) calc(var(--unit) * 4px), calc(var(--unit) * 5px) calc(var(--unit) * 3px), calc(var(--unit) * 6px) calc(var(--unit) * 3px), calc(var(--unit) * 6px) calc(var(--unit) * 2px), calc(var(--unit) * 7px) calc(var(--unit) * 2px), calc(var(--unit) * 7px) calc(var(--unit) * 1px), calc(var(--unit) * 8px) calc(var(--unit) * 1px), calc(var(--unit) * 8px) calc(var(--unit) * 2px), calc(var(--unit) * 8px) 0, calc(var(--unit) * 13.5px) 0, calc(var(--unit) * 13.5px) calc(var(--unit) * 3px), calc(var(--unit) * 15.5px) calc(var(--unit) * 3px), calc(var(--unit) * 15.5px) calc(var(--unit) * 5px), calc(var(--unit) * 14.5px) calc(var(--unit) * 5px), calc(var(--unit) * 14.5px) calc(var(--unit) * 4px), calc(var(--unit) * 13.5px) calc(var(--unit) * 4px), calc(var(--unit) * 13.5px) calc(var(--unit) * 8px), 0 calc(var(--unit) * 8px));
  clip-path: polygon(0 0, calc(var(--unit) * 1px) 0, calc(var(--unit) * 1px) calc(var(--unit) * 2px), calc(var(--unit) * 2px) calc(var(--unit) * 2px), calc(var(--unit) * 2px) calc(var(--unit) * 3px), calc(var(--unit) * 3px) calc(var(--unit) * 3px), calc(var(--unit) * 3px) calc(var(--unit) * 4px), calc(var(--unit) * 4px) calc(var(--unit) * 4px), calc(var(--unit) * 5px) calc(var(--unit) * 4px), calc(var(--unit) * 5px) calc(var(--unit) * 3px), calc(var(--unit) * 6px) calc(var(--unit) * 3px), calc(var(--unit) * 6px) calc(var(--unit) * 2px), calc(var(--unit) * 7px) calc(var(--unit) * 2px), calc(var(--unit) * 7px) calc(var(--unit) * 1px), calc(var(--unit) * 8px) calc(var(--unit) * 1px), calc(var(--unit) * 8px) calc(var(--unit) * 2px), calc(var(--unit) * 8px) 0, calc(var(--unit) * 13.5px) 0, calc(var(--unit) * 13.5px) calc(var(--unit) * 3px), calc(var(--unit) * 15.5px) calc(var(--unit) * 3px), calc(var(--unit) * 15.5px) calc(var(--unit) * 5px), calc(var(--unit) * 14.5px) calc(var(--unit) * 5px), calc(var(--unit) * 14.5px) calc(var(--unit) * 4px), calc(var(--unit) * 13.5px) calc(var(--unit) * 4px), calc(var(--unit) * 13.5px) calc(var(--unit) * 8px), 0 calc(var(--unit) * 8px));
  background: #000;
  height: calc(var(--unit) * 8px);
  position: absolute;
  right: calc(var(--unit) * 3px);
  top: calc((((var(--unit) * 2) + (var(--unit) * 7)) * 1px) - 1px);
  width: calc(var(--unit) * 16px);
}

.flyer {
  -webkit-animation: fly 10s infinite linear;
  animation: fly 10s infinite linear;
  height: calc(var(--unit) * 3px);
  position: absolute;
  right: 5%;
  top: 35%;
  width: calc(var(--unit) * 7px);
}

.flyer__body {
  -webkit-clip-path: polygon(0 40%, 30% 0, 35% 0, 35% 20%, 40% 20%, 40% 40%, 70% 40%, 70% 50%, 75% 50%, 75% 60%, 100% 60%, 100% 70%, 90% 70%, 90% 80%, 95% 80%, 95% 90%, 85% 90%, 85% 100%, 60% 100%, 60% 90%, 55% 90%, 55% 90%, 50% 90%, 50% 80%, 45% 80%, 45% 80%, 40% 80%, 40% 70%, 35% 70%, 30% 70%, 30% 40%);
  background: #000;
  clip-path: polygon(0 40%, 30% 0, 35% 0, 35% 20%, 40% 20%, 40% 40%, 70% 40%, 70% 50%, 75% 50%, 75% 60%, 100% 60%, 100% 70%, 90% 70%, 90% 80%, 95% 80%, 95% 90%, 85% 90%, 85% 100%, 60% 100%, 60% 90%, 55% 90%, 55% 90%, 50% 90%, 50% 80%, 45% 80%, 45% 80%, 40% 80%, 40% 70%, 35% 70%, 30% 70%, 30% 40%);
  height: 100%;
  width: 100%;
}

.flyer__wing {
  -webkit-clip-path: polygon(0 0, 0 100%, 20% 100%, 20% 90%, 40% 90%, 40% 80%, 40% 70%, 60% 70%, 60% 60%, 100% 0);
  -webkit-animation: flap 2s infinite linear;
  animation: flap 2s infinite linear;
  background: #000;
  clip-path: polygon(0 0, 0 100%, 20% 100%, 20% 90%, 40% 90%, 40% 80%, 40% 70%, 60% 70%, 60% 60%, 100% 0);
  height: 100%;
  left: 40%;
  position: absolute;
  top: 40%;
  transform-origin: top;
  width: 30%;
}

.landscape {
  border-top: calc((var(--unit) / 2) * 1px) solid #000;
  height: 10%;
  position: absolute;
  top: 92%;
  width: 100%;
  z-index: 4;
}

.landscape:after,
.landscape:before {
  content: '';
  background: #000;
  position: absolute;
}

.landscape:after {
  height: calc(var(--unit) * 0.5px);
  left: 50%;
  top: 50%;
  transform: translate(-75%, -100%);
  width: calc(var(--unit) * 2px);
}

.landscape:before {
  height: calc(var(--unit) * 0.5px);
  left: 50%;
  top: 50%;
  transform: translate(250%, -200%);
  width: calc(var(--unit) * 0.5px);
}

.cactus {
  -webkit-clip-path: polygon(0 15%, 20% 15%, 20% 40%, 40% 40%, 40% 0, 60% 0, 60% 35%, 80% 35%, 80% 5%, 100% 5%, 100% 50%, 60% 50%, 60% 100%, 40% 100%, 40% 55%, 0 55%);
  background: #000;
  clip-path: polygon(0 15%, 20% 15%, 20% 40%, 40% 40%, 40% 0, 60% 0, 60% 35%, 80% 35%, 80% 5%, 100% 5%, 100% 50%, 60% 50%, 60% 100%, 40% 100%, 40% 55%, 0 55%);
  height: 150%;
  position: absolute;
  width: 8%;
}

.cactus:nth-of-type(1) {
  left: 35%;
  transform: translate(0, -75%);
}

.cactus:nth-of-type(2) {
  left: 60%;
  transform: translate(0, -85%) rotateY(180deg);
}

@-webkit-keyframes roar {

  0%,
  15%,
  85%,
  100% {
    transform: rotate(0deg);
  }

  35%,
  65% {
    transform: rotate(-25deg);
  }
}

@keyframes roar {

  0%,
  15%,
  85%,
  100% {
    transform: rotate(0deg);
  }

  35%,
  65% {
    transform: rotate(-25deg);
  }
}

@-webkit-keyframes flap {
  50% {
    transform: rotateX(180deg);
  }
}

@keyframes flap {
  50% {
    transform: rotateX(180deg);
  }
}

@-webkit-keyframes fly {
  0% {
    transform: translate(200%);
  }

  80%,
  100% {
    transform: translate(-400%);
  }
}

@keyframes fly {
  0% {
    transform: translate(200%);
  }

  80%,
  100% {
    transform: translate(-400%);
  }
}